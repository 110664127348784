
const Memes = [
  {id: 47, rating: 10, src: require("../assets/memes/VID_5164.mp4"), title: "meme47", modal: false, type: "video"},
  {id: 48, rating: 10, src: require("../assets/memes/VID_6754.mp4"), title: "meme48", modal: false, type: "video"},
  {id: 49, rating: 10, src: require("../assets/memes/VID_6985.mp4"), title: "meme49", modal: false, type: "video"},
  {id: 11, rating: 9, src: require("../assets/memes/IMG_6539.JPG"), title: "meme11", modal: false, type: "image"},
  {id: 21, rating: 9, src: require("../assets/memes/IMG_7041.JPG"), title: "meme21", modal: false, type: "image"},
  {id: 34, rating: 9, src: require("../assets/memes/IMG_7583.JPG"), title: "meme34", modal: false, type: "image"},
  {id: 35, rating: 9, src: require("../assets/memes/IMG_7598.JPG"), title: "meme35", modal: false, type: "image"},
  {id: 12, rating: 8, src: require("../assets/memes/IMG_6540.JPG"), title: "meme12", modal: false, type: "image"},
  {id: 14, rating: 8, src: require("../assets/memes/IMG_6558.JPG"), title: "meme14", modal: false, type: "image"},
  {id: 50, rating: 8, src: require("../assets/memes/IMG_5645.webp"), title: "meme50", modal: false, type: "image"},
  {id: 18, rating: 8, src: require("../assets/memes/IMG_6791.JPG"), title: "meme18", modal: false, type: "image"},
  {id: 22, rating: 8, src: require("../assets/memes/IMG_7289.JPG"), title: "meme22", modal: false, type: "image"},
  {id: 30, rating: 8, src: require("../assets/memes/IMG_7501.JPG"), title: "meme30", modal: false, type: "image"},
  {id: 37, rating: 8, src: require("../assets/memes/IMG_7739.JPG"), title: "meme37", modal: false, type: "image"},
  {id: 42, rating: 8, src: require("../assets/memes/IMG_7852.JPG"), title: "meme42", modal: false, type: "image"},
  {id: 17, rating: 8, src: require("../assets/memes/IMG_6624.JPG"), title: "meme17", modal: false, type: "image"},
  {id: 44, rating: 8, src: require("../assets/memes/IMG_7856.JPG"), title: "meme44", modal: false, type: "image"},
  {id: 9, rating: 7, src: require("../assets/memes/IMG_6490.JPG"), title: "meme9", modal: false, type: "image"},
  {id: 10, rating: 7, src: require("../assets/memes/IMG_6505.JPG"), title: "meme10", modal: false, type: "image"},
  {id: 15, rating: 7, src: require("../assets/memes/IMG_6559.JPG"), title: "meme15", modal: false, type: "image"},
  {id: 32, rating: 7, src: require("../assets/memes/IMG_7544.JPG"), title: "meme32", modal: false, type: "image"},
  {id: 36, rating: 7, src: require("../assets/memes/IMG_7709.JPG"), title: "meme36", modal: false, type: "image"},
  {id: 51, rating: 7, src: require("../assets/memes/IMG_5689.webp"), title: "meme51", modal: false, type: "image"},
  {id: 43, rating: 7, src: require("../assets/memes/IMG_7853.JPG"), title: "meme43", modal: false, type: "image"},
  {id: 33, rating: 6, src: require("../assets/memes/IMG_7545.JPG"), title: "meme33", modal: false, type: "image"},
  {id: 40, rating: 6, src: require("../assets/memes/IMG_7849.JPG"), title: "meme40", modal: false, type: "image"},
  {id: 41, rating: 6, src: require("../assets/memes/IMG_7850.JPG"), title: "meme41", modal: false, type: "image"},
  {id: 45, rating: 6, src: require("../assets/memes/IMG_7857.JPG"), title: "meme45", modal: false, type: "image"},
  {id: 13, rating: 6, src: require("../assets/memes/IMG_6551.JPG"), title: "meme13", modal: false, type: "image"},
  {id: 8, rating: 6, src: require("../assets/memes/IMG_6484.JPG"), title: "meme8", modal: false, type: "image"},
  {id: 19, rating: 6, src: require("../assets/memes/IMG_6894.JPG"), title: "meme19", modal: false, type: "image"},
  {id: 26, rating: 6, src: require("../assets/memes/IMG_7436.JPG"), title: "meme26", modal: false, type: "image"},
  {id: 16, rating: 5, src: require("../assets/memes/IMG_6566.JPG"), title: "meme16", modal: false, type: "image"},
  {id: 24, rating: 5, src: require("../assets/memes/IMG_7355.JPG"), title: "meme24", modal: false, type: "image"},
  {id: 46, rating: 5, src: require("../assets/memes/IMG_7508.JPG"), title: "meme46", modal: false, type: "image"},
  {id: 29, rating: 5, src: require("../assets/memes/IMG_7490.JPG"), title: "meme29", modal: false, type: "image"},
  {id: 38, rating: 5, src: require("../assets/memes/IMG_7763.JPG"), title: "meme38", modal: false, type: "image"},
  {id: 39, rating: 5, src: require("../assets/memes/IMG_7776.JPG"), title: "meme39", modal: false, type: "image"},
  {id: 4, rating: 5, src: require("../assets/memes/IMG_6344.JPG"), title: "meme4", modal: false, type: "image"},
  {id: 7, rating: 5, src: require("../assets/memes/IMG_6348.JPG"), title: "meme7", modal: false, type: "image"},
  {id: 6, rating: 4, src: require("../assets/memes/IMG_6346.JPG"), title: "meme6", modal: false, type: "image"},
  {id: 23, rating: 4, src: require("../assets/memes/IMG_7327.JPG"), title: "meme23", modal: false, type: "image"},
  {id: 20, rating: 4, src: require("../assets/memes/IMG_7017.JPG"), title: "meme20", modal: false, type: "image"},
  {id: 25, rating: 4, src: require("../assets/memes/IMG_7405.JPG"), title: "meme25", modal: false, type: "image"},
  {id: 28, rating: 4, src: require("../assets/memes/IMG_7489.JPG"), title: "meme28", modal: false, type: "image"},
  {id: 3, rating: 3, src: require("../assets/memes/IMG_6343.JPG"), title: "meme3", modal: false, type: "image"},
  {id: 2, rating: 3, src: require("../assets/memes/IMG_6339.JPG"), title: "meme2", modal: false, type: "image"},
  {id: 27, rating: 3, src: require("../assets/memes/IMG_7455.JPG"), title: "meme27", modal: false, type: "image"},
  {id: 31, rating: 3, src: require("../assets/memes/IMG_7506.JPG"), title: "meme31", modal: false, type: "image"},
  {id: 5, rating: 2, src: require("../assets/memes/IMG_6345.JPG"), title: "meme5", modal: false, type: "image"},
  {id: 1, rating: 1, src: require("../assets/memes/IMG_2446.JPG"), title: "meme1", modal: false, type: "image"},
  // next upload: id: 52
];

export default Memes;