import React from "react";

export default function Announcements() {
  return (
    <div id="announcements">
      <p></p>
      {/* <p>Welcome to my personal website</p> */}
    </div>
  );
}
