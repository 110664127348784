import React from "react";

function Footer() {
  return (
    <div className="page-footer">
      {/* <h5>Built with React</h5> */}
    </div>
  );
}

export default Footer;