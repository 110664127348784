import React from "react";

export default function Projecs() {
  return (
    <div className="container" id="projecs">
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          Not Found
        </div>
      </div>
    </div>
  );
}
